.react-datepicker-wrapper {
  width: 100% !important;
}
#addMedicineLastFilled,
#addMedicineLastPrescribed {
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #e1e1e1 !important;
  height: 38px !important;
}
