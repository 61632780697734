.modal {
    display: block;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.newsletter-popup {
    max-width: 53% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 77%;
    max-height: 100%;
    overflow-y: auto;
} 

.modal-content {
    border: none;
    box-shadow: none;
    padding-top: 20px;
    height: 100%;
}

.modal-header {
    padding-bottom: 0px;
    border-bottom: None;
}

.modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.modal-footer {
    border-top: none;
    padding-top: 0px;
}

.paragraph-spacing {
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .modal {
        margin-top: 0px;
        padding-top: 0px;
    }

    .modal-dialog{
        max-width: 100% !important;
        top: 0;
        left: 0;
        margin-top: 12%;
        height: 77%;
        transform: none;
        width: auto;
     }
    
    .modal-body {
        overflow-y: auto;
        max-height: 100% !important;
    }
    
    .close-btn {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
        z-index: 9999;
        color: #333;
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .reference-text-bottom {
        text-align: justify;
    }
  }

