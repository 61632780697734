.datepicker {
    width: 200;
}
.page-container {
    padding-top: 30px;
}

.react-datepicker-wrapper .is-invalid input {
    border-color: #dc3545;
}

.react-time-picker .is-invalid input {
    border-color: #dc3545;
}

.invalid-feedback {
    color: #dc3545;
    display: block;
    font-size: 80%;
    margin-top: 0.25rem;
}

.header-row{
    padding-left: 0px;
}




@media only screen and (max-width: 600px) {
    .nodal-dialog{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
    .modal .modal-body {
        max-height: 350px;
        overflow-y: auto;
    }
}

