@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

:root {
  --primary-color: #3685fb;
  --primary-color-dark: #2f73d9;
  --secondary-color: #fafcff;
  --text-dark: #0d213f;
  --text-light: #767268;
  --extra-light: #ffffff;
  --max-width: 1200px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/**/
.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
}

.section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

.section__title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.section__subtitle {
  font-size: 1rem;
  color: var(--text-dark);
  max-width: calc(var(--max-width) / 2);
}

.btn {
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--extra-light);
  background-color: var(--primary-color);
  border-radius: 5rem;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  background-color: var(--primary-color-dark);
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  display: block;
}




.link a:hover {
  color: var(--primary-color);
}

header {
  background-color: var(--secondary-color);
}

.header__container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.header__image {
  position: relative;
}

.header__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0.5rem solid var(--extra-light);
  border-radius: 2rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.header__image img:nth-child(1) {
  max-width: 500px;
  transform: translate(-75%, -50%);
}

.header__image img:nth-child(2) {
  max-width: 350px;
  transform: translate(0%, -25%);
}

@media (max-width: 1000px) {
  .header__image img:nth-child(1) {
    max-width: 100%;
    transform: translate(-75%, -50%);
  }

  .header__image img:nth-child(2) {
    max-width: 100%;
    transform: translate(0%, -25%);
    /* Remove any transform for proper centering */
  }
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.header__content > div {
  max-width: 400px;
  display: grid;
  gap: 1rem;
}

.header__content .sub__header {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
}

.header__content h1 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 800;
  color: var(--text-dark);
}

.header__content .action__btns {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;
}

.story {
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
}




@media (width < 1200px) {
  .header__image img:nth-child(1) {
    max-width: 300px;
  }

  .header__image img:nth-child(2) {
    max-width: 200px;
  }
}

@media (max-width: 900px) {
  .nav__links {
    display: none;
  }

  .header__container {
    grid-template-columns: 2fr;
  }

  .header__image {
    min-height: 500px;
  }
}

@media (max-width: 600px) {
  .gallery__container {
    grid-template-columns: 1fr;
  }

  .subscribe__container {
    grid-template-columns: 1fr;
  }

  .footer__container {
    grid-template-columns: 1fr;
  }
}

.header-padding {
  padding-left: '7rem';
  padding-right: '3rem';
}

@media (max-width: 600px) {
  .header-padding {
    padding-left: '0.5rem';
    padding-right: '0.5rem';
  }
}
