.profile-container {
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    .profile-container {
      width: 100%;
    }
}

.profile-box {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-box .btn-edit {
  background-color: #007bff;
  color: white;
  border: none;
}

.questionnaire-box {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.questionnaire-box h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.question-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.question-table th,
.question-table td {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: center;
}

.question-table th {
  background-color: #e9ecef;
}

.question-table input[type="radio"] {
  margin: 0;
  cursor: pointer;
}

.btn-submit,
.btn-question {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit {
  background-color: #28a745;
}

.btn-question {
  background-color: #007bff;
  margin-right: 0.5rem;
}

.initial-prompt {
  text-align: center;
}


.symptoms-container {
    background-color: #f8f9fa;
    color: #030000;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.symptom-section {
    margin-bottom: 40px;
}

.symptom-section h2 {
    font-size: 1.8em;
    color: #4CAF50;
    margin-bottom: 10px;
}

.symptom-section ul {
    list-style-type: none;
    padding-left: 0;
}

.symptom-section li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.symptom-section li:last-child {
    border-bottom: none;
}

.symptom-section li strong {
    color: #333;
}

