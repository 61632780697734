.signup-container {
  border-radius: 10px;
  border: 2px solid #ffffff;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 800px;
  background-color: white;
  padding-bottom: 30px;
  
}

/* 600, 601, 768, 992, 1200*/

@media (max-width: 600px) {
  .signup-container {
    width: 100%;
    align-items: center;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.signup-company-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 50px;
  font-size: 50px;
  font-weight: 500px;
  font-family: sans-serif;
}

.signup-inputs {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.signup-input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 400px;
  height: 60px;
  background: rgb(236, 236, 236);
  border-radius: 10px;
}

.signup-input img {
  margin: 0px 30px;
  user-select: none;
}

.signup-input input {
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: #000000;
  font-size: 18px;
}

.signup-user-type {
  display: flex;
  justify-content: center;
  margin: 20px;
  gap: 10px;
  font-size: 15px;
  font-family: sans-serif;
}

.signup-user-type label {
  display: flex;
  align-items: center;
}

.signup-user-type input[type='radio'] {
  margin-right: 5px;
}


.signup-submit-container{
  display: flex;
  margin: 10px auto;
}

.signup-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  color: white;
  background: #28a745;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 900px;
  cursor: pointer;
}

.signup-already-account {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 15px;
  font-family: sans-serif;
}

.signup-signup-external-link {
  padding-left: 8px;
}
.signup-already-account span {
  color: #036cff;
  cursor: pointer;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: inherit;
}

.signup-body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  font-size: 15px;
  user-select: none;
}

.signup-apple-sign-up {
  padding-left: 39%;
  margin-top: 4%;
  color: black;
  font-size: 20px;
  align-items: center;
}

.signup-apple-sign-up span {
  padding-left: 2%;
  color: black;
  cursor: pointer;
  font-size: 17px;
  font-family: sans-serif;
}

.siginup-apple-external-link,
.siginup-google-external-link,
.siginin-signup-external-link,
.siginin-terms-of-use,
.siginin-privacy-policy {
  color: inherit;
  text-decoration: none;
}

.signup-apple-external-link:hover,
.signup-google-external-link,
.siginin-signup-external-link {
  text-decoration: none;
}

.signup-google-sign-up {
  padding-left: 38.2%;
  margin-top: 2%;
  font-size: 20px;
  color: black;
}

.signup-google-sign-up span {
  padding-left: 2%;
  color: black;
  cursor: pointer;
  font-size: 17px;
  font-family: sans-serif;
}

.signup-terms-and-policy {
  /*position: fixed;*/
  bottom: 0;
  left: 1;
  right: 0;
  display: flex;
  justify-content: center;
  background: rgb(
    255,
    255,
    255
  ); /* You can adjust the background color as needed */
  padding-top: 15px;
  font-size: 15px;
}



