.chat-icon {
  position: fixed;
  bottom: 20px;   
  right: 20px;    
  background-color: #dad8ec;  
  color: rgb(104, 58, 58);    
  padding: 10px;  
  border-radius: 50%;  
  cursor: pointer; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  
  transition: background-color 0.3s;  
}

.chat-icon:hover {
  background-color: #50507a;  
}

.chatbot-container {
  position: fixed;
  bottom: 80px; 
  right: 20px;   
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  z-index: 1000;  
  background-color: white;  
  border-radius: 8px;  
  overflow: hidden; 
}
