.section__container {
    max-width: var(--max-width);
    margin: auto;
    padding: 5rem 1rem;
  }

  .section__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 4rem;
  }

  .section__title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 1rem;
  }

  .section__subtitle {
    font-size: 1rem;
    color: var(--text-dark);
    max-width: calc(var(--max-width) / 2);
  }

  .btn {
    padding: 0.75rem 2rem;
    outline: none;
    border: none;
    font-size: 1rem;
    color: var(--extra-light);
    background-color: var(--primary-color);
    border-radius: 5rem;
    cursor: pointer;
    transition: 0.3s;
  }

  .btn:hover {
    background-color: var(--primary-color-dark);
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    display: block;
  }

  .gallary__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .image__gallary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .gallary__col {
    display: grid;
    place-content: center;
    gap: 1rem;
  }

  .gallary__col img {
    border-radius: 1rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  }

  .gallary__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .gallary__content > div {
    max-width: 400px;
  }

  .gallary__content .section__subtitle {
    margin-bottom: 2rem;
  }

  @media (max-width: 600px) {
    .gallary__container {
      grid-template-columns: 1fr;
    }
  }

  
