@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }




  .text-highlight {
    font-style: normal;
    border-radius: 1em 0 1em 0
  }

  .text-highlight.yellow {
    background: #e4e66b;
    background-image: linear-gradient(-100deg, #fbf349, #e4e66b 100%)
  }

  .text-highlight.purple {
    background: #fccdff;
    background-image: linear-gradient(-100deg, #fccdff, #f657ff 100%,#fff)
  }
 

  
@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1.5s fadeInUp;
}

.carousel_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}


.main-container {
  width: 100%;
}

@media (max-width: 600px) {
  .main-container {
    width: fit-content;
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
  }

  .offcanvas-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  /* Newsletter pop css */
  .close-btn-home {
    font-size: xx-large;
    border: 1px solid #333;
    border-radius: 40px;
    padding: 5px;
    padding-top: 0px;
    top: 8px;
    right: 8px;
  }

  .modal-dialog-home {
    border-radius: 16px;
  }
}

