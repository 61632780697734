@media (max-width: 600px) {
    .newsletter-container {
      width: fit-content;
    }

    /* Newsletter popup css */
    .modal-dialog-nl {
      margin-left: 4px;
      margin-right: 4px;
    }

    .close-btn-nl {
      font-size: large;
      top: 0px !important;
      right: 0px !important;
      height: 16px;
      width: 16px;
      border: 1px solid #333;
      border-radius: 40px;
      margin: 3px;
      padding-bottom: 2px;
    }

    .modal-header-nl {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 2px;
    }

    .modal-title-nl {
      font-size: 11.5px;
    }

    .modal-body-nl {
      font-size: 9.3px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .paragraph-spacing-nl {
      margin-bottom: 9px;
    }

    .reference-nl {
      font-size: 9px;
      margin-bottom: 6px !important
    }

    .reference-text-bottom-nl {
      margin-bottom: 8px;
    }
  }
  