/* App.css */

/* Updated Color Palette */
:root {
  --primary-color: #0d6efd; /* Dark Blue */
  --secondary-color: hsl(218, 50%, 64%); /* Green */
  --accent-color: #ffffff; /* White */
  --background-color: #f5f7f8; /* Off White */
  --text-color: #000000; /* Black */
}

/* Body Background */
body {
  background-color: var(--background-color);
  font-family: Arial, sans-serif;
}

/* Container Styles */
.trail-container1 {
  text-align: center;
  color: var(--text-color);
}

/* List Styles */
.trail-container1 li {
  font-size: 16px;
  margin: 9px 450px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--accent-color);
  padding: 6px 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.trail-container1 li:hover {
  background-color: var(--secondary-color);
}

/* Table Styles */
.appointment-table1 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.appointment-table1 th,
.appointment-table1 td {
  border: 1px solid var(--background-color);
  padding: 10px;
  text-align: left;
}

.appointment-table1 th {
  background-color: var(--accent-color);
  color: var(--text-color);
}

.appointment-table1 tr:nth-child(even) {
  background-color: var(--background-color);
}

/* Additional Styling for Modern Look */
.details-container1 {
  background-color: var(--accent-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}
