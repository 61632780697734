/* Base styles */
body {
  font-family: 'Helvetica', Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #2D3748;
}

/* Container */
.signin-container {
  display: flex;
  min-height: 100vh;
}
.signin-submit-container {
  margin-top: 1rem; /* Adjust this value to increase or decrease the gap */
  justify-content: center;
  display: flex; 
}
/* Image side */
.signin-image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: none;
}

.signin-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signin-image-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

/* Form side */
.signin-form-container {
  width: 100%;
  max-width: 27.5rem;
  background: linear-gradient(to bottom right, #EBF8FF, #FFFFFF);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.signin-form-inner {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #E6F0FD;
}

/* Logo and title */
.signin-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.signin-logo-icon {
  width: 3rem;
  height: 3rem;
  color: #2B6CB0;
  margin-bottom: 0.5rem;
}

.signin-title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #2D3748;
}

/* Form elements */
.signin-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.signin-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.signin-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4A5568;
}

.signin-input-wrapper {
  position: relative;
}

.signin-input-icon {
  position: absolute;
  /*left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);*/
  color: #4A5568;
  width: 1.25rem;
  height: 1.25rem;
}

.signin-input {
  width: 100%;
  padding: 0.625rem 1.5rem 0.625rem 0.75rem;
  border: 2.5px solid #E2E8F0;
  border-radius: 0.5rem;
  background-color: #FFFFFF;
  color: #2D3748;
  transition: all 0.3s ease;
  display: flex;
  align-items: center; 

}
.signin-input img {
  margin-right: 18px;            /* Space between icon and input */
}

.signin-input:focus {
  outline: none;
  border-color: #4299E1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.signin-button {
  padding: 0.625rem 1.25rem;
  background: linear-gradient(to right, #2B6CB0, #4299E1);
  color: #FFFFFF;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signin-button:hover {
  background: linear-gradient(to right, #2C5282, #2B6CB0);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Links */
.signin-links {
  margin-top: 1.5rem;
  text-align: center;
}

.signin-forgot-password {
  color: #2B6CB0;
  font-size: 0.875rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signin-forgot-password:hover {
  color: #2C5282;
}

.signin-signup {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4A5568;
}

.signin-signup-link {
  color: #2B6CB0;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signin-signup-link:hover {
  color: #2C5282;
}

/* Footer */
.signin-footer {
  margin-top: 2rem;
  text-align: center;
}

.signin-footer-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.signin-footer-link {
  color: #4A5568;
  font-size: 0.75rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signin-footer-link:hover {
  color: #2B6CB0;
}

.signin-copyright {
  font-size: 0.75rem;
  color: #718096;
}

/* Responsive design */
@media (min-width: 768px) {
  .signin-image-container {
    display: block;
  }

  .signin-form-container {
    width: 40%;
  }
}
