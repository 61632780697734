.slider-container-pain {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 50px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e0dada;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e0dada;
    cursor: pointer;
  }
  
  .slider-value {
    margin-top: 10px;
    font-size: 1.5em;
  }
  
  /* Custom checkbox styles */
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .checkbox-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .custom-checkbox {
    position: relative;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
    transition: background-color 0.2s;
  }
  
  .checkbox-container input:checked + .custom-checkbox {
    background-color: #2196f3;
  }
  
  .custom-checkbox::after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input:checked + .custom-checkbox::after {
    display: block;
  }
  
  .checkbox-container .custom-checkbox::after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }