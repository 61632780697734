/* chipInput.css */

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.input-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    align-items: flex-start; /* Align items to the start to handle vertical growth */
    background-color: transparent;
    border-radius: 0.5rem;
    padding: 10px;
    border: 1px solid #ced4da;
    border-color: 1px #ced4da;
    box-sizing: border-box; /* Ensure padding and border are included in the height calculation */
    min-height: 50px; /* Adjust as needed to handle growth */
}

.chips {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    flex-wrap: wrap; /* Allow chips to wrap to the next line */
}

.chip {
    background-color: #cfe1ff;
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 0.5rem;
    margin-right: 5px;
    font-size: 17px; /* Ensure font size consistency */
}

input {
    font-size: 1.6rem;
    background: transparent;
    border: none;
    outline: none;
    flex: 1; /* Allow the input to take up remaining space */
    min-height: 2em; /* Adjust to ensure enough space for the placeholder */
    box-sizing: border-box; /* Ensure padding and border are included in the height */
}

.input-container:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}
