/* Specific styles for the "All Records" tab */
.all-records-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.all-records-table th,
.all-records-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  vertical-align: top;
  word-wrap: break-word;
}

/* Define column widths */
.all-records-table .col-date {
  width: 20%;
}

.all-records-table .col-location,
.all-records-table .col-description,
.all-records-table .col-provider {
  width: 30%;
}

.all-records-table .col-doctor,
.all-records-table .col-amount,
.all-records-table .col-policy {
  width: 25%;
}

.all-records-table .col-comments {
  width: 25%;
}

.all-records-table th {
  background-color: #f2f2f2; /* Match the header color of Billing History */
  color: #000;
}

.all-records-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Light grey */
}

.all-records-table tr:nth-child(odd) {
  background-color: #ffffff; /* White */
}

.appointment-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.appointment-table th,
.appointment-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  vertical-align: top;
  word-wrap: break-word;
}

.appointment-table th {
  background-color: var(--background-color); /* Default background color */
  color: var(--text-color); /* Default text color */
}

.appointment-table tr:nth-child(even) {
  background-color: var(--background-color); /* Default alternate row color */
}

.appointment-table tr:nth-child(odd) {
  background-color: var(--accent-color); /* Default row color */
}

/* Other Styles */
.details-container {
  background-color: var(--accent-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  display: inline-block;
  width: 100%
}

.section-heading {
  margin-top: 20px;
  margin-bottom: 10px;
}
