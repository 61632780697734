.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    margin: 20px;
    padding: 20px;
    width: 280px;
    min-height: 70px;
    display: grid;
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    background: linear-gradient(#f0f0f0, #aac3f1);
  }
  
  .card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }
  
  .card__link,
  .card__exit,
  .card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
  }
  .card__link{
    Button {
       margin-bottom: 6px; 
    }
  }
  
  .card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s;
  }
  
  .card__link:hover::after {
    width: 100%;
  }
  
  .card__exit {
    grid-row: 1/2;
    justify-self: end;
  }
  
  .card__icon {
    grid-row: 2/3;
    font-size: 30px;
  }
  
  .card__title {
    grid-row: 3/4;
    font-weight: 400;
    color: #ffffff;
  }
  
  .card__apply {
    grid-row: 4/5;
    align-self: center;
  }
  
  /* CARD BACKGROUNDS */
  
  .card-1 {
    background: #635f5fb5;
  }
  
  .card-2 {
    background: #635f5fb5;
  }
  
  .card-3 {
    background: #635f5fb5;
  }
  
  
/*   
  /* RESPONSIVE */

 @media (max-width: 1600px) {
    .cards {
      justify-content: center;
    }
  } 