/* NewsletterForm.css */

/* Container */
.newsletter-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  /* Heading */
  .newsletter-form-title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Labels */
  .newsletter-form-label {
    font-weight: bold;
    margin-top: 10px;
    display: block;
    color: #555;
  }
  
  /* Input fields */
  .newsletter-form-input,
  .newsletter-form-textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Textarea */
  .newsletter-form-textarea {
    resize: vertical;
  }
  
  /* Button */
  .newsletter-form-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .newsletter-form-button:hover {
    background-color: #45a049;
  }
  
  /* Feedback Messages */
  .newsletter-success-message {
    text-align: center;
    font-weight: bold;
    color: green;
  }
  
  .newsletter-failure-message {
    text-align: center;
    font-weight: bold;
    color: red;
  }
  