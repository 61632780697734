
#fitness-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh; /* Set height to full viewport height */
  margin: 0 auto;
  padding: 0 10px; /* Add padding to ensure content doesn't touch the edges */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.quote {
  width: 90vw; /* Full viewport width */
  text-align: center;
}
.fitness-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping if there are too many cards for one row */
  justify-content: center; /* Center align items in the container */
  width: 100%;
  gap: 20px; /* Space between cards */
}

.fitness-card {
  width: 345px; /* Fixed width */
  height: 420px; /* Fixed height */
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  animation: fadeIn 0.5s ease-in;
  background: linear-gradient(145deg, #f3f4f6, #ffffff);
  box-sizing: border-box; /* Include padding and border in width and height calculation */
}

/* slider css */

.full-width-form {
  width: 100%;
}

.form-group-full-width {
  width: 100%;
}

.slider-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top:5px;
}

.slider-container .form-group-full-width {
  flex: 1; /* Ensures the form group takes up available space */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: auto; /* Adjust based on your design needs */
  padding-left: 10px;
  margin-top:30px;
}

.accordion-body {
  max-height: 200px;
  overflow-y: auto;
}

.fitness-card:hover {
  transform: translateY(-10px);
}

.fitness-card .card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 15px;
}
.border-blue {
  border-color: #61dafb;
  border-width: 1.5px;
}

.border-pink {
  border-color: pink;
  border-width: 1.5px;
}

.border-green {
  border-color: rgb(125, 212, 125);
  border-width: 1.5px;
}

.fitness-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it appears above other content */
}

.dialog-content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.caloriesconsume {
  color: #228B22 !important;
}

.caloriesburn {
  color: #F82716 !important;
}

.waterconsume {
  color: #093861 !important;
}
